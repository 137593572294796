<script>

export default {

  name: 'CargoPerfilV2',
  computed: {
    empresa() {
      return this.$store.getters.userLoggedIn.empresa
    },
    cargoId() {
      return this.$route.params.id
    },
    logoSucursalURL() {
      if( this.cargo.sucursal && this.cargo.sucursal.urlLogo) {
        return this.cargo.sucursal.urlLogo
      } 
      return this.empresa.urlLogo
    }
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({
      path: `Cargo/GetCargoById/${this.cargoId}?extended=true`
    });
    this.cargo = await response.json();
    
    const response2 = await this.$store.getters.fetchGet({
      path: `PerfilCargo/PerfilCargoAll2024/${this.cargoId}`
    });
    const perfilCargo = await response2.json();
    console.log(perfilCargo);
  },
  data() {
    return {
      cargo: {}
    }
  },
  methods: {
    getSucursalByCargoId( cargoId ) {
      console.log( cargoId );
    }
  }

}
</script>

<template>
  <div class="container-fluid">
    <b-card>
      <div>
        <div class="header-view">
          <figure>
            <img :src="logoSucursalURL" :alt="cargo?.sucursal?.nombre">
          </figure>
        </div>
        <div>
          <h3>PERFIL Y FUNCIONES DE CARGO</h3>
          <h4>Talento Humano</h4>
        </div>
      </div>
      
      <!-- <h4>Nombre del cargo: {{ cargo?.nombre }}</h4> -->
      <!-- <HelexForm>
       codigo
        version
        implementacion
        clasificacion 

        <div class="wrapper-input" title="Código">
          <label for="codigo">Código</label>
          <b-form-input id="codigo" name="codigo" required></b-form-input>
        </div>
      </HelexForm> -->
    </b-card>
  </div>
</template>

<style scoped>

figure {
  width: 100px;
  height: 100px;
  overflow: hidden;
  line-height: 0;
  margin: 0;
}

figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

</style>